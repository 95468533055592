import Vue from 'vue';
import validators from '@/mixins/validators';
import { AuthNavigationButtons } from '@/views';
import { SigninData } from '@/pages/auth/signin/signin-types';
import { mapActions } from 'vuex';
import { AUTH_VIEW as authView } from '@/store/client/modules/auth-page/auth-page-types';

export default Vue.extend({
  components: {
    AuthNavigationButtons,
  },
  mixins: [validators],
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  data(): SigninData {
    return {
      password: '',
      isFormValid: false,
      showPassword: false,
    };
  },
  methods: {
    ...mapActions('authPage', ['changeView']),

    openResetView(): void {
      this.changeView({
        currentView: authView.reset,
      });
    },
  },
});
