import { render, staticRenderFns } from "./receive-invoice.html?vue&type=template&id=55eb61ac&"
import script from "./receive-invoice.ts?vue&type=script&lang=js&"
export * from "./receive-invoice.ts?vue&type=script&lang=js&"
import style0 from "./receive-invoice.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./receive-invoice.i18n.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodals%2Fprofile-settings%2Freceive-invoice%2Freceive-invoice.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports