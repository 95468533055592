import Vue from 'vue';
import { amplitudeInit } from '@/utils/analytics.ts';

export default Vue.extend({
  metaInfo: {
    title: 'Cryptocurrency Payroll Accounting Software',
    titleTemplate: '%s - PaymentX',
    meta: [
      {
        vmid: 'description',
        property: 'description',
        content:
          'PaymentX provides crypto payroll accounting services for business. Pay employees using cryptocurrencies to their wallet.',
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content: 'Manage cryptocurrency payroll seamlessly',
      },
      {
        vmid: 'og:description',
        property: 'og:title',
        content:
          'PaymentX provides crypto payroll accounting services for business. Pay employees using cryptocurrencies to their wallet.',
      },
      {
        vmid: 'twitter:card',
        property: 'twitter:card',
        content: 'summary',
      },
    ],
  },
  created () {
    amplitudeInit(null)
  },
});
