import Vue from 'vue';

export default Vue.extend({
  props: {
    timeLimit: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      timePassed: 0,
      isTimerFinished: false,
      timerInterval: null as unknown,
    };
  },
  computed: {
    formattedTimeLeft(): string {
      const timeLeft = this.timeLeft;
      const minutes = Math.floor(timeLeft / 60);
      let seconds: {} = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },
    timeLeft(): number {
      return Number(this.timeLimit) - this.timePassed;
    },
  },
  watch: {
    timeLeft: {
      handler(value): void {
        if (value === 0) {
          this.stopTimer();
        }
      },
    },
  },
  mounted() {
    this.startTimer();
  },
  methods: {
    stopTimer(): void {
      this.isTimerFinished = true;
      clearInterval(this.timerInterval as NodeJS.Timeout);
    },

    startTimer(): void {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },

    restartTimer(): void {
      this.timePassed = 0;
      this.timerInterval = null;
      this.isTimerFinished = false;

      this.startTimer();
    },
  },
});
