import Vue from 'vue';
import { mapActions, mapMutations, mapState } from 'vuex';

export default Vue.extend({
  props: {
    active: {
      type: String,
      required: true,
      validator: (value: string): boolean =>
        ['profile', 'password', 'logout'].includes(value),
    },
  },
  computed: {
    ...mapState('profileSettingsModal', {
      currentComponent: 'component',
    }),

    icon(): string {
      const iconName: { [key: string]: string } = {
        profile: 'settings',
        password: 'password',
        logout: 'logout',
      };

      return iconName[this.active];
    },
  },
  methods: {
    ...mapMutations('profileSettingsModal', ['changeComponent']),
    ...mapActions('modals', {
      modalClose: 'close',
    }),

    onClick(): void {
      if (this.active === 'logout') {
        this.$router.push('/auth');
        this.modalClose();

        return;
      }

      this.changeComponent(this.active);
    },
  },
});
