import Vue from 'vue';
import MnemonicContainer from '../mnemonic-container/mnemonic-container.vue';
import { TEMP_MNEMONICS } from '@/modals/wallet-create/wallet-backup/wallet-backup-mock-data';

const shuffleElements = (mnemonic: string[]): string[] => {
  return mnemonic.sort(() => Math.random() - 0.5);
};

const uniqRandomNumbers = (): number[] => {
  const numbers: number[] = [];

  while (numbers.length < 3) {
    const randomNumber = Math.floor(Math.random() * 11 + 1);
    if (numbers.indexOf(randomNumber) === -1) {
      numbers.push(randomNumber);
    }
  }

  return numbers.sort((a, b) => a - b);
};

const orderNumber = (number: number): string => {
  const order: { [key: number]: string } = {
    1: '1st',
    2: '2nd',
    3: '3rd',
  };

  return order[number] || `${number}th`;
};

export default Vue.extend({
  components: {
    MnemonicContainer,
  },
  computed: {
    randomMnemonics(): string[] {
      return shuffleElements(TEMP_MNEMONICS);
    },
    randomNumbers(): number[] {
      return uniqRandomNumbers();
    },
    orderedNumbers(): string {
      return this.randomNumbers
        .map(number => orderNumber(number))
        .toString()
        .replace(/,/g, ', ')
        .replace(/,([^,]*)$/, ` ${this.$t('global.and')}$1`);
    },
  },
});
