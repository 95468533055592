import Vue from 'vue';
import { LinksData } from './links-types';

export default Vue.extend({
  data(): LinksData {
    return {
      links: [
        {
          link: 'https://twitter.com/Payment_X',
          linkAlt: 'twitter',
          image: require('@/assets/imgs/contacts/twitter.svg'),
        },
        {
          link: 'https://www.facebook.com/paymentx/',
          linkAlt: 'facebook',
          image: require('@/assets/imgs/contacts/facebook.svg'),
        },
        {
          link: 'https://medium.com/paymentx',
          linkAlt: 'medium',
          image: require('@/assets/imgs/contacts/medium.svg'),
        },
        {
          link: 'https://t.me/paymentx',
          linkAlt: 'telegram',
          image: require('@/assets/imgs/contacts/telegram.svg'),
        },
        {
          link: 'https://www.linkedin.com/company/paymentx/',
          linkAlt: 'linkedin',
          image: require('@/assets/imgs/contacts/linkedin.svg'),
        },
      ],
    };
  },
});
