import Vue from 'vue';
import validators from '@/mixins/validators';
import { AuthNavigationButtons } from '@/views';
import { SignupData } from '@/pages/auth/signup/signup-types';
import { mapState } from 'vuex';

export default Vue.extend({
  components: {
    AuthNavigationButtons,
  },
  mixins: [validators],
  data(): SignupData {
    return {
      isFormValid: false,
      code: '',
    };
  },
  computed: {
    ...mapState('authPage', ['meta']),

    email(): string {
      return this.meta.email;
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    resend(): void {},
  },
});
