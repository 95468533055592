import { State as RootState } from '@/store/state';
import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { UserState } from '@/store/client/modules/user/user-types';

export const STATE: UserState = {
  passportUser: null,
  bearer: null,
  refreshToken: null,
  expirationTime: null,
};

export const actions: ActionTree<UserState, RootState> = {};

export const mutations: MutationTree<UserState> = {};

export const getters: GetterTree<UserState, RootState> = {};

export const UserModule: Module<UserState, RootState> = {
  namespaced: true,
  state: { ...STATE },
  getters,
  actions,
  mutations,
};

export default UserModule;
