// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/icons-sprite-green.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".wallet_success__container{text-align:center;width:385px}.wallet_success__container .wallet_success__icon_block{display:-webkit-box;display:-ms-flexbox;display:flex;padding-top:20px;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}.wallet_success__container .wallet_success__icon_block .wallet_success__icon{-webkit-transform:scale(2);transform:scale(2);margin:0 0 18px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:-168px 0;min-width:24px;width:24px;max-width:24px;min-height:24px;height:24px;max-height:24px;cursor:pointer;background-repeat:no-repeat}.wallet_success__container .wallet_success__title_block{margin:5px 0;color:#b9babf;font-weight:700;font-size:16px;line-height:120%}.wallet_success__container .wallet_success__info_block{color:#b9babf;font-weight:400;font-size:14px;line-height:140%;margin:5px 0}.wallet_success__container .wallet__action_block{margin-top:14px;-ms-flex-pack:distribute !important;justify-content:space-around !important}.wallet_success__container .wallet__action_block .wallet__button.outlined.v-btn{width:195px !important}.wallet_success__container .wallet__action_block .wallet__button.filled.v-btn{width:110px !important}", ""]);
// Exports
module.exports = exports;
