import { render, staticRenderFns } from "./app-image-uploader.html?vue&type=template&id=f593b5f0&"
import script from "./app-image-uploader.ts?vue&type=script&lang=js&"
export * from "./app-image-uploader.ts?vue&type=script&lang=js&"
import style0 from "./app-image-uploader.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./app-image-uploader.i18n.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Fapp-image-uploader%2Fapp-image-uploader.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports