import Vue from 'vue';
import { ContactsForm } from '@/views';

export default Vue.extend({
  metaInfo: {
    title: 'Contacts',
  },
  components: {
    ContactsForm,
  },
});
