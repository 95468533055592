import Vue from 'vue';
import Vuex, { Payload, Store, StoreOptions } from 'vuex';
import Cookies from 'js-cookie';
import VuexPersistence from 'vuex-persist';
import { localForageService } from './localForageService';
import createLogger from 'vuex/dist/logger';
import * as modulesShared from './shared';

const loggerPlugin = createLogger();

Vue.use(Vuex);

const plugins = [];

plugins.push(loggerPlugin);

if (process.client) {
  const vuexCookie = new VuexPersistence<Payload>({
    restoreState: key => Cookies.getJSON(key),
    saveState: (key, state) =>
      Cookies.set(key, state, {
        expires: 3,
      }),
    modules: ['user'], //only save user module
    filter: mutation => mutation.type == 'logIn' || mutation.type == 'logOut',
  });
  plugins.push(vuexCookie.plugin);
  const vuexLocal = new VuexPersistence<Payload>({
    storage: localForageService,
    modules: ['user'],
    asyncStorage: true,
  });
  plugins.push(vuexLocal.plugin);
}

const storeOptions: StoreOptions<Payload> = {
  plugins,
  modules: Object.entries(modulesShared)
    .map(([key, factory]) => ({ key, value: factory() }))
    .reduce((t, entry) => ({ ...t, [entry.key]: entry.value }), {}),
};

const store: Store<{}> = new Vuex.Store(storeOptions);

export default store;
