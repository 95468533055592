// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/invoice-preview.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".billing_info__wrapper .billing_info__hint_block{margin-top:16px}.billing_info__wrapper .billing_info__hint_block .billing_info__hint_title_wrapper{color:#5b5d69;margin-bottom:10px;font-weight:400;font-size:14px;line-height:140%}.billing_info__wrapper .billing_info__hint_block .billing_info__hint_wrapper{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");position:relative;min-height:280px;display:-webkit-box;display:-ms-flexbox;display:flex;width:632px;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;padding:30px 30px 120px 10px}.billing_info__wrapper .billing_info__hint_block .billing_info__hint_wrapper .billing_info__hint_logo{width:178px;height:64px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;top:26px;left:26px;color:#d9dbe4;position:absolute;border:2px dashed #f0f2f9;-webkit-box-sizing:border-box;box-sizing:border-box;border-radius:6px;font-weight:700;font-size:16px;line-height:120%}.billing_info__wrapper .billing_info__hint_block .billing_info__hint_wrapper .billing_info__hint_info{max-width:300px;white-space:pre;overflow:hidden;font-weight:400;font-size:16px;line-height:150%;color:#151828}.billing_info__wrapper .billing_info__hint_block .billing_info__hint_wrapper .billing_info__hint_info div{white-space:pre;overflow:hidden;text-overflow:ellipsis;font-style:normal;font-weight:400;font-size:11px;line-height:130%;text-align:right;color:#151828}.billing_info__wrapper .billing_info__hint_block .billing_info__hint_wrapper .billing_info__invoice_number{font-family:Arial,serif;font-style:normal;font-weight:700;font-size:33px;line-height:172%;color:#000;position:absolute;bottom:70px;left:40px}", ""]);
// Exports
module.exports = exports;
