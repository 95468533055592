import Vue, { VNode } from 'vue';
import App from './app.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/moment';
import './styles/main.scss';
import i18n from './plugins/i18n';
import './plugins/vuelidate';
import './plugins/check-view';
import './plugins/vue-meta';

Vue.config.productionTip = false;

export default (): Vue => {
  return new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: (h): VNode => h(App),
  });
};
