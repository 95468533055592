export type Component = 'profile' | 'password';

export interface ProfileSettingsModal {
  component: Component;
}

export const PROFILE_MODULE_COMPONENT: { [key: string]: string } = {
  profile: 'profile',
  password: 'password',
};
