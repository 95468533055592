import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import uniqid from 'uniqid';
import { State as RootState } from '@/store/state';
import {
  Modal,
  ModalState,
  ModalsContext,
} from '@/store/client/modules/modals/modals-types';

export const STATE: ModalState = {
  stack: [],
};

export const actions: ActionTree<ModalState, RootState> = {
  open({ commit }: ModalsContext, modal: Modal) {
    commit('modalOpen', modal);
  },
  close({ commit }: ModalsContext) {
    commit('modalClose');
  },
};

export const mutations: MutationTree<ModalState> = {
  modalOpen(state: ModalState, modal: Modal) {
    state.stack.push({
      id: uniqid(),
      modal,
    });
  },
  modalClose(state: ModalState) {
    state.stack.pop();
  },
};

export const getters: GetterTree<ModalState, RootState> = {};

export const ModalsModule: Module<ModalState, RootState> = {
  namespaced: true,
  state: { ...STATE },
  getters,
  actions,
  mutations,
};

export default ModalsModule;
