import { render, staticRenderFns } from "./auth-navigation-buttons.html?vue&type=template&id=b8a8c9c4&"
import script from "./auth-navigation-buttons.ts?vue&type=script&lang=js&"
export * from "./auth-navigation-buttons.ts?vue&type=script&lang=js&"
import style0 from "./auth-navigation-buttons.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./auth-navigation-buttons.i18n.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Fauth-navigation-buttons%2Fauth-navigation-buttons.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports