import Vue from 'vue';
import FeatureCard from './feature-card/feature-card.vue';
import { cardAnimations as animations } from '@/views/main/common';
import { MainFeaturesData } from './main-features-types';
import CardAnimation from '@/mixins/card-animation.ts';

export default Vue.extend({
  components: {
    FeatureCard,
  },
  mixins: [CardAnimation],
  data(): MainFeaturesData {
    return {
      FEATURES: [
        {
          title: this.$t('card.first.title'),
          description: this.$t('card.first.description'),
          image: require('@/assets/img/feature_card_1.svg'),
        },
        {
          title: this.$t('card.second.title'),
          description: this.$t('card.second.description'),
          image: require('@/assets/img/feature_card_2.svg'),
        },
        {
          title: this.$t('card.third.title'),
          description: this.$t('card.third.description'),
          image: require('@/assets/img/feature_card_3.svg'),
        },
      ],
      animations,
    };
  },
});
