import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { State as RootState } from '@/store/state';
import {
  Component,
  ProfileSettingsModal,
} from './profile-settings-modal-types';

export const STATE: ProfileSettingsModal = {
  component: 'profile',
};

export const actions: ActionTree<ProfileSettingsModal, RootState> = {};

export const mutations: MutationTree<ProfileSettingsModal> = {
  changeComponent(state: ProfileSettingsModal, component: Component): void {
    state.component = component;
  },

  resetFields(state: ProfileSettingsModal) {
    Object.assign(state, STATE);
  },
};

export const getters: GetterTree<ProfileSettingsModal, RootState> = {};

export const ProfileSettingsModalModule: Module<
  ProfileSettingsModal,
  RootState
> = {
  namespaced: true,
  state: { ...STATE },
  getters,
  actions,
  mutations,
};

export default ProfileSettingsModalModule;
