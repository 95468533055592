import Vue from 'vue';
import { AppButton } from '@/components';
import { mapState } from 'vuex';
import SidebarNavButton from './sidebar-nav-button/sidebar-nav-button.vue';
import { PROFILE_MODULE_COMPONENT as component } from '@/store/client/modules/profile-settings-modal/profile-settings-modal-types';
import { SidebarData } from './sidebar-types';

export default Vue.extend({
  components: {
    AppButton,
    SidebarNavButton,
  },
  data(): SidebarData {
    return {
      component,
    };
  },
  computed: {
    ...mapState('profileSettingsModal', {
      currentComponent: 'component',
    }),
  },
});
