import Vue from 'vue';
import FindCard from './find-card/find-card.vue';
import { AppButton } from '@/components';
import { cardAnimations as animations } from '@/views/main/common';
import { MainFindMoreData } from './main-find-more-types';
import CardAnimation from '@/mixins/card-animation.ts';

export default Vue.extend({
  components: {
    FindCard,
    AppButton,
  },
  mixins: [CardAnimation],
  data(): MainFindMoreData {
    return {
      PROCESSES: [
        {
          title: this.$t('card.first.title'),
          description: this.$t('card.first.description'),
          image: require('@/assets/img/process_1.svg'),
        },

        {
          title: this.$t('card.second.title'),
          description: this.$t('card.second.description'),
          image: require('@/assets/img/process_2.svg'),
        },

        {
          title: this.$t('card.third.title'),
          description: this.$t('card.third.description'),
          image: require('@/assets/img/process_3.svg'),
        },
      ],

      animations,
    };
  },
});
