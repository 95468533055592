import { render, staticRenderFns } from "./table-item.html?vue&type=template&id=0706ac36&"
import script from "./table-item.ts?vue&type=script&lang=js&"
export * from "./table-item.ts?vue&type=script&lang=js&"
import style0 from "./table-item.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./table-item.i18n.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodals%2Fadd-invoice%2Finvoice-items%2Ftable-item%2Ftable-item.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports