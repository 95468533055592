import Vue from 'vue';
import { BLOCKCHAINS as blockchains } from '@/common';
import validators from '@/mixins/validators';
import { AppButton } from '@/components';
import { WalletSelectData } from './wallet-select-types';

export default Vue.extend({
  components: {
    AppButton,
  },
  mixins: [validators],
  props: {
    sectionType: {
      type: String,
      required: true,
      validator: (v: string): boolean =>
        ['create', 'add', 'recover'].includes(v),
    },
  },
  data(): WalletSelectData {
    return {
      blockchains,
      form: {
        name: '',
        blockchain: '',
        address: '',
      },
    };
  },
});
