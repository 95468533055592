import Vue, { PropType } from 'vue';
import { Wallet } from '@/client/wallets-types';

export default Vue.extend({
  props: {
    wallet: {
      type: Object as PropType<Wallet>,
      required: true,
    },
  },
});
