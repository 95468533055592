import Vue from 'vue';
import validators from '@/mixins/validators';
import { mapActions } from 'vuex';
import { CheckData } from '@/pages/auth/check/check-types';
import { AUTH_VIEW as authView } from '@/store/client/modules/auth-page/auth-page-types';

export default Vue.extend({
  mixins: [validators],
  data(): CheckData {
    return {
      isFormValid: true,
      email: '',
    };
  },
  methods: {
    ...mapActions('authPage', ['changeView']),

    async check(): Promise<void> {
      // There will be request for check if user exist or not
      const currentView = authView.signin;

      this.changeView({
        currentView,
        meta: {
          email: this.email,
        },
      });
    },
  },
});
