import { render, staticRenderFns } from "./change-password.html?vue&type=template&id=265b4d96&"
import script from "./change-password.ts?vue&type=script&lang=js&"
export * from "./change-password.ts?vue&type=script&lang=js&"
import style0 from "./change-password.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./change-password.i18n.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodals%2Fprofile-settings%2Fchange-password%2Fchange-password.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports