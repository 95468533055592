// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/icons-sprite-light-grey.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".invoice_details__container{width:492px}.invoice_details__container .logo_uploader{margin-top:30px;margin-bottom:50px}.invoice_details__container .invoice_details__image_preview .invoice_details__image_wrapper{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.invoice_details__container .invoice_details__image_preview .invoice_details__image_wrapper img{max-width:250px;max-height:250px;padding:15px 18px;border:1px solid #f0f2f9;border-radius:6px;-webkit-box-sizing:content-box;box-sizing:content-box;margin-right:15px}.invoice_details__container .invoice_details__image_preview .invoice_details__image_wrapper .invoice_details__delete_button{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:-216px -24px;min-width:24px;width:24px;max-width:24px;min-height:24px;height:24px;max-height:24px;cursor:pointer;background-repeat:no-repeat}", ""]);
// Exports
module.exports = exports;
