import { render, staticRenderFns } from "./policy.html?vue&type=template&id=2e561050&"
import script from "./policy.ts?vue&type=script&lang=js&"
export * from "./policy.ts?vue&type=script&lang=js&"
import style0 from "./policy.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./policy.i18n.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fpages%2Fpublic%2Fpolicy%2Fpolicy.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports