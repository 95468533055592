import { Request } from '@/client/requests-types';

export const mockedData: Request[] = [
  {
    invoice: {
      updateNumber: 0,
      items: [
        {
          type: 'hours',
          description: 'asdasd',
          count: 1,
          rate: 1,
        },
      ],
      attachments: [],
      logo: null,
      number: '00001',
      payerAddress: 'melisovm97@gmail.com',
      payerName: '',
      payeeName: '',
      payeeAddress: 'melisovm97@gmail.com',
      title: '',
      due: '2020-01-23',
      billFrom: '',
      transactions: [],
    },
    statusHistory: [
      {
        status: 'Unpaid',
        date: '2020-01-09T04:37:39.951Z',
      },
      {
        status: 'Cancelled',
        date: '2020-01-09T04:39:03.169Z',
      },
      {
        status: 'Cancelled',
        date: '2020-01-09T04:39:03.169Z',
      },
    ],
    type: 'request',
    userEmail: 'melisovm97@gmail.com',
    userTag: 'melisovm97@gmail.com',
    settlementAsset: 'USD',
    settlementAmount: 1,
    paymentAsset: 'BTC',
    paymentWallet: 'mnYohWstKr3GtGgLepyHCfnFqodGr8kgmL',
    status: 'Cancelled',
    notes: '',
    checkoutUrl: '',
    fromUserId: '5e039f071dd00700137c5985',
    userId: '5e039f071dd00700137c5985',
    createdAt: '2020-01-09T04:37:39.951Z',
    updatedAt: '2020-01-09T04:39:03.171Z',
    paymentBlockchain: 'bitcoin',
    paymentNetwork: 'testnet',
    isPaid: false,
    requestId: '5e16ae13d0e9d600160af865',
  },
  {
    invoice: {
      updateNumber: 0,
      items: [
        {
          type: 'hours',
          description: '1',
          count: 1,
          rate: 1,
        },
      ],
      attachments: [],
      logo: null,
      number: '00002',
      payerAddress: 'test@test.test',
      payerName: '',
      payeeName: '',
      payeeAddress: 'melisovm97@gmail.com',
      title: '',
      due: '2020-03-23',
      billFrom: null,
      transactions: [],
    },
    statusHistory: [
      {
        status: 'Unpaid',
        date: '2020-03-09T08:46:25.621Z',
      },
    ],
    type: 'request',
    userEmail: 'test@test.test',
    userTag: 'test@test.test',
    settlementAsset: 'USD',
    settlementAmount: 1,
    paymentAsset: 'BTC',
    paymentWallet: 'mnYohWstKr3GtGgLepyHCfnFqodGr8kgmL',
    status: 'Unpaid',
    notes: '',
    checkoutUrl: '',
    paymentBlockchain: 'bitcoin',
    paymentNetwork: 'testnet',
    fromUserId: '5e039f071dd00700137c5985',
    userId: '5e66026118df48004889950d',
    createdAt: '2020-03-09T08:46:25.621Z',
    updatedAt: '2020-03-09T08:46:25.621Z',
    isPaid: false,
    requestId: '5e66026101e66c00354aa001',
  },
];
