// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/section_4_bg.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".main_find_more__container{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50% 100%;background-size:100%;background-repeat:no-repeat;min-height:200px}.main_find_more__container .main__container{padding-bottom:90px;padding-top:190px;text-align:center}.main_find_more__container .main__container .main_find_more__subtitle{font-size:23px;margin-top:0;font-weight:400;line-height:20px;color:rgba(21,24,40,.7)}.main_find_more__container .main__container .main_find_more__card_block{grid-column-gap:30px}@media only screen and (max-width: 1264px){.main_find_more__container .main__container .main_find_more__card_block{grid-column-gap:10px;overflow-x:hidden;width:90%;margin:auto}}.main_find_more__container .main_find_more__action_block{padding-bottom:240px}@media only screen and (min-width: 600px)and (max-width: 959px){.main_find_more__container .main__container{padding-top:80px}.main_find_more__container .main_find_more__action_block{padding-bottom:120px}}@media only screen and (max-width: 599px){.main_find_more__container .main__container{padding-top:80px}.main_find_more__container .main__container .main_find_more__subtitle{font-size:17px;line-height:23px;margin-bottom:0}.main_find_more__container .main_find_more__action_block{padding-bottom:80px}}", ""]);
// Exports
module.exports = exports;
