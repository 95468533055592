import { Wallet } from './wallets-types';

export const mockedData: Wallet[] = [
  {
    type: 'default',
    createdAt: 1576851926705,
    updatedAt: 1576851926705,
    name: 'test wallet',
    address: 'mnYohWstKr3GtGgLepyHCfnFqodGr8kgmL',
    namespace: 'paymentX',
    blockchain: 'BTC',
    meta: {
      symbol: 'BTC',
      payDefault: false,
      recieveDefault: false,
      network: 'testnet',
    },
    id: '5e039f98ad30f00013f53ebd',
  },
  {
    type: 'default',
    createdAt: 1583414031071,
    updatedAt: 1583414031071,
    name: 'test',
    address: '0x065c99ee7bace0ec094b95df377fe0a230ba7fd2',
    namespace: 'paymentX',
    blockchain: 'ETH',
    meta: {
      symbol: 'ETH',
      payDefault: false,
      recieveDefault: false,
      network: 'mainnet',
    },
    id: '5e6760d9d63b4b0013ec82e9',
  },
];
