import Vue from 'vue';
import { mapFields } from 'vuex-map-fields';
import AppImageUploader from '@/views/app-image-uploader/app-image-uploader.vue';
import { mapMutations, mapState } from 'vuex';
import { InvoiceDetailsData } from './invoice-details-types';

export default Vue.extend({
  components: {
    AppImageUploader,
  },
  data(): InvoiceDetailsData {
    return {
      openDatePicker: false,
    };
  },
  computed: {
    ...mapFields('addInvoiceModal', {
      logo: 'logo',
      email: 'to',
      from: 'from',
      title: 'title',
      number: 'number',
      due: 'due',
    }),
    ...mapState('addInvoiceModal', ['showFrom', 'showTitle']),
  },
  methods: {
    ...mapMutations('addInvoiceModal', ['setField']),

    onImageUploaded(image: string): void {
      this.setLogo(image);
    },

    setLogo(value: string): void {
      this.setField({ field: 'logo', value });
    },

    deleteLogo(): void {
      this.setLogo('');
    },
  },
});
