import Vue from 'vue';
import { MainHeader, MainFooter } from '@/views';
import { Sidebar, ModalStack } from '@/views';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Route } from 'vue-router';
import { mapState } from 'vuex';

export default Vue.extend({
  components: {
    MainHeader,
    MainFooter,
    Sidebar,
    ModalStack,
  },
  data: () => ({
    userLayout: false,
  }),
  computed: {
    ...mapState('modals', {
      modals: 'stack',
    }),
  },
  watch: {
    $route: {
      handler(route: Route): void {
        this.userLayout = route.matched[0].name === 'user';
      },
      deep: true,
      immediate: true,
    },
  },
  mounted(): void {
    AOS.init({
      duration: 1500,
    });
  },
});
