import Vue from 'vue';
import { amplitudeSendEvent } from '@/utils/analytics';

export default Vue.extend({
  props: {
    type: {
      type: String,
      required: false,
      default: 'default',
    },

    to: {
      type: String,
      required: false,
      default: null,
    },

    color: {
      type: String,
      required: false,
      default: 'default',
    },

    size: {
      type: Number,
      required: false,
      default: 16,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    href: {
      type: String,
      required: false,
      default: null,
    },
    target: {
      type: String,
      required: false,
      default: '_self',
      validator: (value: string): boolean =>
        ['_blank', '_self', '_parent', '_top'].includes(value),
    },
    eventAmplitude: {
      type: String,
      default: null,
    },
    eventSource: {
      type: String,
      default: null,
    },
  },
  methods: {
    onClick(): void {
      if (this.eventAmplitude) {
        amplitudeSendEvent(
          this.eventAmplitude,
          this.eventSource ? { Source: this.eventSource } : {},
        );
      }
      setImmediate(() => {
        this.$emit('click');
      });
    },
  },
});
