import Vue, { PropType } from 'vue';
import { AppModal } from '@/components';
import { Stack } from '@/store/client/modules/modals/modals-types';

export default Vue.extend({
  components: {
    AppModal,
  },
  props: {
    stack: {
      type: Array as PropType<Stack[]>,
      required: true,
    },
  },
});
