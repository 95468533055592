import { RouteConfig } from 'vue-router';
import {
  Auth,
  Main,
  Policy,
  Terms,
  Contacts,
  Invoices,
  NotFound,
  Pricing
} from '@/pages';
import { Layout } from '@/views';
// import { checkUser } from './middlewares';

const routes: RouteConfig[] = [
  {
    path: '',
    redirect: { name: 'main' },
  },

  {
    path: '',
    component: Layout,
    redirect: { name: 'main' },
    children: [
      {
        path: '',
        name: 'main',
        component: Main,
      },

      {
        path: '/contacts',
        name: 'contacts',
        component: Contacts,
      },

      {
        path: '/policy',
        name: 'policy',
        component: Policy,
      },

      {
        path: '/tc',
        name: 'terms',
        component: Terms,
      },

      {
        path: '/auth',
        name: 'auth',
        component: Auth,
      },

      {
        path: '/pricing',
        name: 'pricing',
        component: Pricing,
      },

      {
        path: '/404',
        name: 'NotFound',
        component: NotFound,
      },
    ],
  },

  {
    path: '/user',
    name: 'user',
    component: Layout,
    redirect: { name: 'invoices' },
    children: [
      {
        path: 'invoices',
        name: 'invoices',
        component: Invoices,
      },
    ],
  },

  {
    path: '*',
    redirect: { name: 'NotFound' },
  },
];

export default routes;
