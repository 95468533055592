import Vue from 'vue';
import AppImageUploader from '@/views/app-image-uploader/app-image-uploader.vue';
import { mapMutations, mapState } from 'vuex';

export default Vue.extend({
  components: {
    AppImageUploader,
  },
  computed: {
    ...mapState('addInvoiceModal', ['attachments']),
  },
  methods: {
    ...mapMutations('addInvoiceModal', ['addAttachment', 'deleteAttachment']),

    onFile(file: File): void {
      this.addAttachment(file);
    },
  },
});
