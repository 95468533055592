import Vue from 'vue';
import { AppImageUploaderData } from './app-image-uploader-types';

export default Vue.extend({
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data(): AppImageUploaderData {
    return {
      isDragging: false,
      dragCount: 0,
    };
  },
  methods: {
    onDragEnter(e: DragEvent): void {
      e.preventDefault();
      this.dragCount += 1;
      this.isDragging = true;
    },
    onDragLeave(e: DragEvent): void {
      e.preventDefault();

      this.dragCount -= 1;

      if (this.dragCount <= 0) {
        this.isDragging = false;
      }
    },
    onDrop(e: DragEvent): void {
      e.preventDefault();
      e.stopPropagation();

      this.isDragging = false;
      const files = e.dataTransfer?.files;

      if (files) {
        for (const file of files) {
          this.emitEvent(file);
        }
      }
    },
    emitEvent(file: File): void {
      if (this.type === 'image') {
        const reader = new FileReader();

        if (!file.type.match('image.*')) {
          return;
        }

        reader.onload = (e): void => {
          this.$emit('image', e.target?.result);
        };

        reader.readAsDataURL(file);
      } else {
        this.$emit('file', file);
      }
    },
    onFileInputChange(e: { target: { files: File[] } }): void {
      const files = e.target.files;

      if (files) {
        for (const file of files) {
          this.emitEvent(file);
        }
      }
    },
  },
});
