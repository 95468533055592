import Vue from 'vue';
import SocialLinks from '@/components/links/links.vue';

export default Vue.extend({
  components: {
    SocialLinks,
  },
  props: {
    userLayout: {
      type: Boolean,
      required: true,
    },
  },
});
