import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';
import { State as RootState } from '@/store/state';
import {
  WalletCreateModalState,
  WalletCreateModalContext,
  Section,
} from './wallet-create-modal-types';

export const STATE: WalletCreateModalState = {
  sections: [],
};

export const getters: GetterTree<WalletCreateModalState, RootState> = {
  getField,
};

export const actions: ActionTree<WalletCreateModalState, RootState> = {
  async expandSection(
    { commit, dispatch }: WalletCreateModalContext,
    id: string
  ) {
    await dispatch('closeSection');

    commit('EXPAND_SECTION', id);
  },
  closeSection({ commit }: WalletCreateModalContext) {
    commit('CLOSE_SECTION');
  },
  fillSections({ commit }: WalletCreateModalContext, sections: Section[]) {
    commit('FILL_SECTIONS', sections);
  },
};

export const mutations: MutationTree<WalletCreateModalState> = {
  updateField,

  EXPAND_SECTION(state: WalletCreateModalState, id: string) {
    const expandableSection = state.sections.find(section => section.id === id);

    if (expandableSection) {
      expandableSection.expanded = true;
    }
  },
  CLOSE_SECTION(state: WalletCreateModalState) {
    const closableSection = state.sections.find(section => section.expanded);

    if (closableSection) {
      closableSection.expanded = false;
    }
  },
  FILL_SECTIONS(state: WalletCreateModalState, sections: Section[]) {
    state.sections = sections;
  },
};

export const WalletCreateModule: Module<WalletCreateModalState, RootState> = {
  namespaced: true,

  state: { ...STATE },
  getters,
  actions,
  mutations,
};

export default WalletCreateModule;
