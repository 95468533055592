import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { AppButton, AppButtonGroup } from '@/components';
import WalletItem from '@/views/dashboard/wallet-item/wallet-item.vue';
import {
  WalletCreate as WalletCreateModal,
  PaymentRequest as PaymentRequestModal,
  InvoiceCreate as InvoiceCreateModal,
} from '@/modals';
import { AccountCardData } from './account-card-types';

export default Vue.extend({
  components: {
    AppButton,
    AppButtonGroup,
    WalletItem,
  },
  data(): AccountCardData {
    return {
      openButtonMenu: false,
    };
  },
  computed: {
    ...mapState('wallets', ['wallets']),
  },
  methods: {
    ...mapActions('modals', {
      modalOpen: 'open',
    }),

    openInvoiceCreateModal(): void {
      this.modalOpen({
        factory: () => InvoiceCreateModal,
        options: {
          fullscreen: true,
        },
        data: {
          title: 'New Invoice',
        },
      });
    },
    openRequestModal(): void {
      this.modalOpen({
        factory: () => PaymentRequestModal,
        options: {
          width: 590,
        },
        data: {
          title: 'New payment request',
        },
      });
    },
    openWalletCreateModal(): void {
      this.modalOpen({
        factory: () => WalletCreateModal,
        options: {
          width: 1000,
          fullscreen: true,
        },
        data: {
          title: 'Create wallet',
        },
      });
    },
  },
});
