import Vue from 'vue';
import { CURRENCIES as currencies } from '@/common';
import { PrimaryAssetData } from './primary-asset-types';

export default Vue.extend({
  data(): PrimaryAssetData {
    return {
      currencies,
    };
  },
});
