// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/icons-sprite-light-grey.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".wallet__container{display:-webkit-box;display:-ms-flexbox;display:flex;margin:10px 25px 10px 0;position:relative;padding:12px 0;cursor:pointer;border-bottom:1px solid #f0f2f9;min-height:90px;font-weight:400;font-size:16px;line-height:150%;color:#151828}.wallet__container .wallet__currency_block{background:#f6fbfb;border-radius:10px;width:46px;height:46px;-webkit-box-flex:0;-ms-flex-positive:0;flex-grow:0;-ms-flex-negative:0;flex-shrink:0;-webkit-box-shadow:0 2px 3px rgba(240,242,249,.5);box-shadow:0 2px 3px rgba(240,242,249,.5);-webkit-box-sizing:border-box;box-sizing:border-box;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}.wallet__container .wallet__currency_block .wallet__currency_icon{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");min-width:24px;width:24px;max-width:24px;min-height:24px;height:24px;max-height:24px;background-repeat:no-repeat}.wallet__container .wallet__currency_block .wallet__currency_icon.btc{background-position:-48px -24px}.wallet__container .wallet__currency_block .wallet__currency_icon.eth{background-position:-288px -72px}.wallet__container .wallet__information_block{padding-left:15px}.wallet__container .wallet__information_block .wallet__name{font-style:normal;font-weight:400;font-size:14px;line-height:140%;color:#8a8b93}.wallet__container .wallet__information_block .wallet__balance{margin-top:10px;margin-bottom:6px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start;font-style:normal;font-weight:700;font-size:16px;line-height:120%;color:rgba(21,24,40,.7)}.wallet__container .wallet__information_block .wallet__fiat_value{font-style:normal;font-weight:400;font-size:13px;line-height:130%;color:#b9babf}", ""]);
// Exports
module.exports = exports;
