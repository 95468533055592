import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { State as RootState } from '@/store/state';
import { Wallet } from '@/client/wallets-types';
import { getWallets } from '@/client/wallets';
import {
  WalletsContext,
  WalletState,
} from '@/store/client/modules/wallets/wallets-types';

export const STATE: WalletState = {
  wallets: [],
};

export const actions: ActionTree<WalletState, RootState> = {
  fetchWallets({ commit }: WalletsContext): void {
    const wallets: Wallet[] = getWallets();

    commit('setWallets', wallets);
  },
};

export const mutations: MutationTree<WalletState> = {
  setWallets(state: WalletState, wallets: Wallet[]) {
    state.wallets = wallets;
  },
};

export const getters: GetterTree<WalletState, RootState> = {};

export const WalletModule: Module<WalletState, RootState> = {
  namespaced: true,
  state: { ...STATE },
  getters,
  actions,
  mutations,
};

export default WalletModule;
