import Vue from 'vue';
import { AuthNavigationButtons } from '@/views';
import validators from '@/mixins/validators';
import { mapState } from 'vuex';
import { AppButton, AppCountdown } from '@/components';

export default Vue.extend({
  components: {
    AuthNavigationButtons,
    AppButton,
    AppCountdown,
  },
  mixins: [validators],
  data() {
    return {
      isFormValid: false,
      form: {
        recoverCode: '',
      },
    };
  },
  computed: {
    ...mapState('authPage', {
      meta: 'meta',
    }),

    email(): string {
      return this.meta.email;
    },
  },
  methods: {
    resetPassword(): void {
      console.log('Reset password', this.email);
    },
  },
});
