// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/icons-sprite-blue.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".wallet_backup__container{min-width:440px}.wallet_backup__container .wallet_backup__info_block{color:#5b5d69;font-weight:400;font-size:14px;line-height:140%}.wallet_backup__container .wallet_backup__info_block .danger{color:#f97c7c}.wallet_backup__container .wallet_backup__mnemonic_block{margin-top:25px;display:grid;grid-template-columns:1fr 1fr 1fr;width:100%;grid-column-gap:20px;grid-row-gap:20px}.wallet_backup__container .margin-t-20{margin-top:20px}.wallet_backup__confirm_mnemonic .wallet_confirm__block{display:grid;grid-template-columns:1fr 75px}.wallet_backup__confirm_mnemonic .wallet_confirm__block .wallet_confirm__reset_button_block{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.wallet_backup__confirm_mnemonic .wallet_confirm__block .wallet_confirm__reset_button_block .wallet_confirm__reset_button_icon{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:-96px 0;min-width:24px;width:24px;max-width:24px;min-height:24px;height:24px;max-height:24px;cursor:pointer;background-repeat:no-repeat;margin-right:10px}.wallet_backup__confirm_mnemonic .wallet_confirm__block .wallet_confirm__reset_button_block .wallet_confirm__reset_button{color:#1436e5;font-weight:400;font-size:14px;line-height:140%}.wallet_backup__confirm_mnemonic .wallet_backup__confirm_block{background:#50eff5;border-radius:5px;padding:11px 0;text-align:center;font-size:14px;line-height:19px;font-weight:700;color:#333;border:1px solid #f6fbfb}", ""]);
// Exports
module.exports = exports;
