// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/icons-sprite-grey.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".user_info__container{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;-webkit-box-align:center;-ms-flex-align:center;align-items:center;cursor:pointer;max-width:240px}.user_info__container .user_info__avatar_block{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.user_info__container .user_info__user_email{font-style:normal;font-weight:700;font-size:16px;line-height:120%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-left:10px}.user_info__container .user_info__user_icon_block{margin-left:10px}.user_info__container .user_info__user_icon_block .user_info__user_icon{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:-168px -24px;min-width:24px;width:24px;max-width:24px;min-height:24px;height:24px;max-height:24px;background-repeat:no-repeat}", ""]);
// Exports
module.exports = exports;
