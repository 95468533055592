import Vue from 'vue';
import { AppButton, LogoContainer } from '@/components';
import { UserInfo } from '@/views/dashboard';
import { mapActions, mapState } from 'vuex';
import { HeaderData } from './header-types';

export default Vue.extend({
  components: {
    LogoContainer,
    AppButton,
    UserInfo,
  },
  props: {
    userLayout: {
      type: Boolean,
      required: true,
    },
  },

  data(): HeaderData {
    return {
      openMenu: false,
    };
  },

  computed: {
    ...mapState('sidebar', ['openRight']),
  },
  methods: {
    ...mapActions('sidebar', ['toggleRight']),
  },
});
