import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { AppButton, ExportInvoices } from '@/components';
import { InvoiceItem, InvoiceTableHeader } from '@/views/dashboard';
import { PreparedInvoice } from '@/client/requests-types';
import { prepareInvoice } from '@/client/requests';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import { Mapped } from './invoices-types';

export const sortedInvoices = function(this: Mapped): PreparedInvoice[] {
  const invoices: PreparedInvoice[] = this.requests.map(request =>
    prepareInvoice(request)
  );

  return [...invoices].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );
};

export const byDay = function(this: {
  sortedInvoices: PreparedInvoice[];
}): object {
  return groupBy(this.sortedInvoices, ({ updatedAt }) =>
    moment(updatedAt)
      .startOf('day')
      .format()
  );
};

export default Vue.extend({
  components: {
    AppButton,
    ExportInvoices,
    InvoiceTableHeader,
    InvoiceItem,
  },
  computed: {
    ...mapState('requests', ['requests']),

    sortedInvoices,
    byDay,
  },
  mounted(): void {
    this.fetchRequests();
  },
  methods: {
    ...mapActions('requests', {
      fetchRequests: 'fetchRequests',
    }),
  },
});
