import Vue from 'vue';
import PaymentItem from './payment-item/payment-item.vue';
import { AppButton } from '@/components';

export default Vue.extend({
  components: {
    PaymentItem,
    AppButton,
  },
});
