// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/icons-sprite-blue.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".app_image_uploader{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:pointer;border:2px dashed #f0f2f9;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;border-radius:6px;padding:18px 15px;margin-bottom:20px;position:relative}.app_image_uploader .drag_wrapper{position:absolute;top:0;right:0;bottom:0;left:0;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;border:2px dashed gray;border-radius:6px;background-color:rgba(255,255,255,.8)}.app_image_uploader .app_image_uploader__icon{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");min-width:24px;width:24px;margin-right:10px;max-width:24px;min-height:24px;height:24px;max-height:24px;cursor:pointer;background-repeat:no-repeat}.app_image_uploader .app_image_uploader__icon.default{background-position:-168px -48px}.app_image_uploader .app_image_uploader__title{color:#1436e5;font-weight:400;font-size:14px;line-height:140%}", ""]);
// Exports
module.exports = exports;
