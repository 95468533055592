import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      percentage: 0,
      element: {} as Element,
      childs: [] as Element[],
    };
  },
  watch: {
    percentage: {
      handler(value): void {
        this.animateCards(value);
      },
    },
  },
  mounted(): void {
    this.element = this.$refs.cardAnimations as Element;
    this.childs = this.$refs.cardAnimations.childNodes as Element[];
  },
  methods: {
    centerPercentage(e: { percentTop: number }): void {
      const size = 1 - e.percentTop;
      const percentage = Math.round(size * 202);
      this.percentage = percentage <= 100 ? percentage : 100;
    },

    animateCards(value): void {
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.element.style.opacity = `${value / 100}`;

        const [firstChild, ...restChilds] = this.childs;
        const lastChild = restChilds.pop();
        firstChild.style.transform = `translate3d(${this.percentage -
          100}px, 0px, 0px)`;

        if (lastChild) {
          lastChild.style.transform = `translate3d(${100 -
            this.percentage}px, 0px, 0px)`;
        }

        for (const key in restChilds) {
          restChilds[key].style.transform = `translate3d(0px, ${100 -
            this.percentage}px, 0px)`;
        }
      } else {
        this.element.style.opacity = '1';

        const [firstChild, ...restChilds] = this.childs;
        const lastChild = restChilds.pop();
        firstChild.style.transform = `translate3d(0px, 0px, 0px)`;

        if (lastChild) {
          lastChild.style.transform = `translate3d(0px, 0px, 0px)`;
        }

        for (const key in restChilds) {
          restChilds[key].style.transform = `translate3d(0px, 0px, 0px)`;
        }
      }
    },
  },
});
