import Vue, { PropType } from 'vue';
import { Section } from '@/store/client/modules/wallet-create-modal/wallet-create-modal-types';
import { mapActions } from 'vuex';
import { MultiStep } from '@/views';
import { WalletSectionData } from './wallet-section-types';

export default Vue.extend({
  components: {
    MultiStep,
  },
  props: {
    section: {
      type: Object as PropType<Section>,
      required: true,
    },
  },
  data(): WalletSectionData {
    return {
      sectionNumber: 0,
    };
  },
  methods: {
    ...mapActions('walletCreateModal', ['expandSection', 'closeSection']),

    async expand(): Promise<void> {
      const { id } = this.section;

      this.expandSection(id);
    },
  },
});
