import Vue from 'vue';
import gsap from 'gsap';
import { AppButton } from '@/components';

export default Vue.extend({
  components: {
    AppButton,
  },

  data: () => ({
    interval: (0 as unknown) as NodeJS.Timeout,
  }),

  mounted(): void {
    this.interval = setInterval(() => {
      const random = Math.floor(Math.random() * 7) + 1;

      gsap.from(`.dot_${random}`, {
        opacity: 1,
        scale: 1,
        ease: 'power1',
        duration: 1,
      });
    }, 1750);
  },

  beforeDestroy(): void {
    clearInterval(this.interval);
  },
});
