import Vue from 'vue';
import validators from '@/mixins/validators';
import { AppButton } from '@/components';
import { LocaleMessages } from 'vue-i18n';
import { WalletPasswordData } from './wallet-password-types';

export default Vue.extend({
  components: {
    AppButton,
  },
  mixins: [validators],
  props: {
    sectionType: {
      type: String,
      required: true,
      validator: (v: string): boolean =>
        ['create', 'add', 'recover'].includes(v),
    },
  },
  data(): WalletPasswordData {
    return {
      showPassword: false,
      showConfirmationPassword: false,
      form: {
        password: '',
        passwordConfirmation: '',
      },
    };
  },
  computed: {
    passwordMatch() {
      return (v: string): string | true | LocaleMessages =>
        this.form.password === v || this.$t('passwordMatchError');
    },
  },
});
