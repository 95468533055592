import Vue from 'vue';
import { MetaInfo } from 'vue-meta';
import { MainBreadcrumb } from '@/views/main';
import { PricingData } from './pricing-types';
import { PricingCard } from '@/views/public';

export default Vue.extend({
  metaInfo(): MetaInfo {
    return {
      title: this.$t('page.name') as string,
    };
  },
  components: {
    MainBreadcrumb,
    PricingCard,
  },
  data(): PricingData {
    return {
      breadcrumbs: [
        {
          text: 'Home',
          disabled: false,
          href: '/',
        },
        {
          text: 'Pricing',
          disabled: true,
          href: '/pricing',
        },
      ],
      plans: [
        {
          name: this.$t('plan.first.name'),
          price: this.$t('plan.first.price'),
          privileges: Object.values(this.$t('plan.first.privileges')),
          buttonText: this.$t('plan.first.buttonText'),
          image: require('@/assets/img/pricing_person.svg'),
        },
        {
          name: this.$t('plan.second.name'),
          price: this.$t('plan.second.price'),
          privileges: Object.values(this.$t('plan.second.privileges')),
          buttonText: this.$t('plan.second.buttonText'),
          image: require('@/assets/img/pricing_people.svg'),
        },
      ],
    };
  },
});
