import Vue from 'vue';
import { AccountCard } from '@/views/dashboard';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';

export default Vue.extend({
  components: {
    AccountCard,
  },
  computed: {
    ...mapFields('sidebar', {
      open: 'openRight',
    }),
  },
  created(): void {
    this.fetchWallets();
  },
  methods: {
    ...mapActions('wallets', {
      fetchWallets: 'fetchWallets',
    }),
  },
});
