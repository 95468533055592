import Vue from 'vue';
import validators from '@/mixins/validators';
import { BLOCKCHAINS as blockchains, CURRENCIES as currencies } from '@/common';
import { PaymentRequestData } from './payment-request-types';

export default Vue.extend({
  mixins: [validators],
  data(): PaymentRequestData {
    return {
      isValid: null,
      blockchains,
      currencies,
      form: {
        blockchain: {},
        currency: {},
        amount: '',
      },
    };
  },
  mounted(): void {
    this.form.blockchain = blockchains[0];
    this.form.currency = currencies[0];
  },
});
