import Vue from 'vue';
import { AppButton } from '@/components';
import { mapGetters } from 'vuex';
import { PreparedInvoice } from '@/client/requests-types';

export default Vue.extend({
  components: {
    AppButton,
  },

  computed: {
    ...mapGetters('requests', { invoices: 'invoices' }),
  },

  methods: {
    exportToCsv(): void {
      let csvContent = 'data:text/csv;charset=utf-8,';
      csvContent += [
        Object.keys(this.invoices[0]).join(';'),
        ...this.invoices.map((item: PreparedInvoice) =>
          Object.values(item).join(';')
        ),
      ]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '');

      const data = encodeURI(csvContent);
      const link = document.createElement('a');

      link.setAttribute('href', data);
      link.setAttribute('download', 'invoices.csv');
      link.click();
    },
  },
});
