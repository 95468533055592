  import Vue from 'vue';
import NoSSR from 'vue-no-ssr';
import { mapGetters, mapActions } from 'vuex';
import { FormsData } from './form-types';
import { required, email } from 'vuelidate/lib/validators';
import VueRecaptcha from 'vue-recaptcha';
import { AppButton } from '@/components';
import { MainBreadcrumb } from '@/views/main';

export default Vue.extend({
  components: {
    AppButton,
    VueRecaptcha,
    'no-ssr': NoSSR,
    MainBreadcrumb,
  },
  data(): FormsData {
    return {
      mailForm: {
        name: '',
        phone: '',
        email: '',
        message: '',
      },
      error: null,
      isBusy: false,
      sendStatus: null,
      breadcrumbs: [
        {
          text: this.$t('button.home'),
          disabled: false,
          to: '/',
        },
        {
          text: this.$t('button.contacts'),
          disabled: true,
          to: 'contacts',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      config: 'config/config',
    }),
    captchaSiteKey() {
      return this.config.captcha.captchaSiteKey;
    },
  },
  validations: {
    mailForm: {
      name: { required },
      phone: {},
      email: { required, email },
      message: { required },
    },
  },
  methods: {
    ...mapActions({
      postEnquiry: 'collaboration/postEnquiry',
    }),
    handleSubmit() {
      console.log(this.captchaSiteKey);
      this.$v.mailForm.$touch();
      if (this.$v.mailForm.$error) {
        return;
      }
      this.$refs.recaptcha.execute();
    },
    handleExpired() {
      console.log('expired');
      this.$refs.recaptcha.reset();
    },
    handleError() {
      console.log('recaptcha error');
    },
    async handlePost(recaptchaToken) {
      console.log('post');
      this.$refs.recaptcha.reset();
      this.isBusy = true;
      this.error = null;
      setImmediate(async () => {
        try {
          this.error = null;
          this.sendStatus = await this.postEnquiry({
            ...this.mailForm,
            recaptchaToken,
          });
          Object.assign(this.mailForm, {
            name: '',
            phone: '',
            email: '',
            message: '',
          });
          this.$v.mailForm.$reset();
        } catch (e) {
          this.error = e.message;
        } finally {
          this.isBusy = false;
        }
      });
    },
  },
});
