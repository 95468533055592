import {
  AddInvoiceModalState,
  InvoiceItem,
  Type,
} from './add-invoice-modal-types';
import { GetterTree, ActionTree, MutationTree, Module } from 'vuex';
import { State as RootState } from '@/store/state';
import { getField, updateField } from 'vuex-map-fields';
import { BLOCKCHAINS as blockchains, CURRENCIES as currencies } from '@/common';
import uniqid from 'uniqid';

export const TYPES: Type[] = [
  {
    type: 'amount',
    name: 'Amount Only',
  },
  {
    type: 'hours',
    name: 'Hours',
  },
  {
    type: 'quantity',
    name: 'Quantity',
  },
];

export const STATE: AddInvoiceModalState = {
  types: TYPES,
  to: '',
  from: '',
  title: '',
  number: '',
  due: '',
  paymentDetails: {
    name: '',
    address: '',
    blockchain: blockchains[0],
  },
  countType: TYPES[0],
  currency: currencies[0],
  items: [
    {
      id: uniqid(),
      description: '',
      amount: 0,
      rate: 0,
      total: 0,
    },
  ],
  itemsTotal: 0,
  attachments: [],
  logo: '',
  note: '',
  showFrom: false,
  showTitle: false,
};

export const getters: GetterTree<AddInvoiceModalState, RootState> = {
  getField,

  itemsTotalAmount(state: AddInvoiceModalState) {
    return state.items.reduce(
      (acc: number, { total }: InvoiceItem) => acc + (total || 0),
      0
    );
  },
};

export const actions: ActionTree<AddInvoiceModalState, RootState> = {};

export const mutations: MutationTree<AddInvoiceModalState> = {
  updateField,

  resetFields(state: AddInvoiceModalState) {
    Object.assign(state, STATE);
  },
  setField(
    state: AddInvoiceModalState,
    { field, value }: { field: string; value: string }
  ) {
    state[field] = value;
  },
  addItem(state: AddInvoiceModalState) {
    state.items.push({
      id: uniqid(),
      description: '',
      rate: 0,
      amount: 0,
      total: 0,
    });
  },
  changeItem(
    state: AddInvoiceModalState,
    { index, value }: { index: number; value: InvoiceItem }
  ) {
    Object.assign(state.items[index], value);
  },
  deleteItem(state: AddInvoiceModalState, index) {
    const isMoreThanOneItem: boolean = state.items.length > 1;
    if (isMoreThanOneItem) {
      state.items.splice(index, 1);
    }
  },
  addAttachment(state: AddInvoiceModalState, file: File) {
    state.attachments.push(file);
  },
  deleteAttachment(state: AddInvoiceModalState, index) {
    state.attachments.splice(index, 1);
  },
  toggleShow(state: AddInvoiceModalState, field) {
    state[field] = !state[field];
  },
};

export const AddInvoiceModalModule: Module<AddInvoiceModalState, RootState> = {
  namespaced: true,

  state: { ...STATE },
  getters,
  actions,
  mutations,
};

export default AddInvoiceModalModule;
