import Vue from 'vue';
import WalletSection from './wallet-section.vue';
import { mapActions, mapState } from 'vuex';
import WalletSelectForm from '@/modals/wallet-create/wallet-select/wallet-select.vue';
import WalletPassword from '@/modals/wallet-create/wallet-password/wallet-password.vue';
import ShowMnemonics from '@/modals/wallet-create/show-mnemonics/show-mnemonic.vue';
import ConfirmMnemonics from '@/modals/wallet-create/confirm-mnemonic/confirm-mnemonic.vue';
import WalletSuccess from '@/modals/wallet-create/wallet-success/wallet-success.vue';
import WalletRecover from '@/modals/wallet-create/wallet-recover/wallet-recover.vue';
import uniqid from 'uniqid';
import { WalletCreateData } from './wallet-create-types';

export default Vue.extend({
  components: {
    WalletSection,
  },
  data(): WalletCreateData {
    return {
      SECTIONS: [
        {
          id: uniqid(),
          title: this.$t('title.create'),
          description: this.$t('description.create'),
          type: 'create',
          expanded: false,
          components: [
            WalletSelectForm,
            WalletPassword,
            ShowMnemonics,
            ConfirmMnemonics,
            WalletSuccess,
          ],
        },
        {
          id: uniqid(),
          title: this.$t('title.add'),
          description: this.$t('description.create'),
          type: 'add',
          expanded: false,
          components: [WalletSelectForm, WalletSuccess],
        },
        {
          id: uniqid(),
          title: this.$t('title.recover'),
          description: this.$t('description.recover'),
          type: 'recover',
          expanded: false,
          components: [
            WalletSelectForm,
            WalletPassword,
            WalletRecover,
            WalletSuccess,
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState('walletCreateModal', ['sections']),
  },
  beforeDestroy(): void {
    this.closeSection();
  },
  created(): void {
    this.fillSections(this.SECTIONS);
  },
  methods: {
    ...mapActions('walletCreateModal', ['closeSection', 'fillSections']),
  },
});
