import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';
import { State as RootState } from '@/store/state';
import {
  SidebarState,
  SidebarContext,
} from '@/store/client/modules/sidebar/sidebar-types';

export const STATE: SidebarState = {
  openRight: true,
};

export const getters: GetterTree<SidebarState, RootState> = {
  getField,
};

export const actions: ActionTree<SidebarState, RootState> = {
  toggleRight({ commit }: SidebarContext): void {
    commit('TOGGLE_RIGHT');
  },
};

export const mutations: MutationTree<SidebarState> = {
  updateField,

  TOGGLE_RIGHT(state: SidebarState) {
    state.openRight = !state.openRight;
  },
};

export const Sidebar: Module<SidebarState, RootState> = {
  namespaced: true,

  state: { ...STATE },
  getters,
  actions,
  mutations,
};

export default Sidebar;
