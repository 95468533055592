import { render, staticRenderFns } from "./main-register.html?vue&type=template&id=c853b2b4&"
import script from "./main-register.ts?vue&type=script&lang=js&"
export * from "./main-register.ts?vue&type=script&lang=js&"
import style0 from "./main-register.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./main-register.i18n.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Fmain%2Fmain-register%2Fmain-register.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports