import Vue from 'vue';
import { mapActions } from 'vuex';

export default Vue.extend({
  methods: {
    ...mapActions('authPage', ['changeView']),

    back(): void {
      this.changeView({
        component: 'check',
      });
    },
  },
});
