import Vue from 'vue';
import { AppButton } from '@/components';
import { mapActions } from 'vuex';
import { LocaleMessage } from 'vue-i18n';

export default Vue.extend({
  components: {
    AppButton,
  },
  props: {
    sectionType: {
      type: String,
      required: true,
      validator: (v: string): boolean =>
        ['create', 'add', 'recover'].includes(v),
    },
  },
  computed: {
    title(): { [key: string]: LocaleMessage } {
      return {
        create: this.$t('title.create'),
        add: this.$t('title.add'),
        recover: this.$t('title.recover'),
      };
    },
  },
  methods: {
    ...mapActions('modals', {
      closeModal: 'close',
    }),
  },
});
