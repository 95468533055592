import Vue from 'vue';
import VueRouter, { RawLocation, Route } from 'vue-router';
import routes from '@/router/routes';
import store from '@/store';

Vue.use(VueRouter);

import Router from 'vue-router';

const originalPush = Router.prototype.push;
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
Router.prototype.push = function push(
  location: RawLocation,
  onResolve: Function | undefined,
  onReject: (err: Error) => void | undefined
): Promise<Route> | void {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return originalPush.call(this, location).catch(err => err);
};

const router = new VueRouter({
  scrollBehavior(): { x: number; y: number } {
    return { x: 0, y: 0 };
  },

  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

if (process.client) {
  const waitForStorageToBeReady = async (
    to: unknown,
    from: unknown,
    next: Function
  ): Promise<unknown> => {
    next();
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    return await store.restored;
  };
  router.beforeEach(waitForStorageToBeReady);
}

export default router;
