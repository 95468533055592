import Vue, { PropType } from 'vue';
import { Feature } from '../main-features-types';

export default Vue.extend({
  props: {
    feature: {
      type: Object as PropType<Feature>,
      required: true,
    },
  },
});
