import { render, staticRenderFns } from "./invoice-items.html?vue&type=template&id=0772dd90&"
import script from "./invoice-items.ts?vue&type=script&lang=js&"
export * from "./invoice-items.ts?vue&type=script&lang=js&"
import style0 from "./invoice-items.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./invoice-items.i18n.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodals%2Fadd-invoice%2Finvoice-items%2Finvoice-items.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports