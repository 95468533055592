import { render, staticRenderFns } from "./wallet-select.html?vue&type=template&id=100e907f&"
import script from "./wallet-select.ts?vue&type=script&lang=js&"
export * from "./wallet-select.ts?vue&type=script&lang=js&"
import style0 from "./wallet-select.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./wallet-select.i18n.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodals%2Fwallet-create%2Fwallet-select%2Fwallet-select.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports