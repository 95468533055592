import Vue from 'vue';
import MnemonicContainer from '../mnemonic-container/mnemonic-container.vue';
import { AppButton } from '@/components';
import { TEMP_MNEMONICS } from '@/modals/wallet-create/wallet-backup/wallet-backup-mock-data';

export default Vue.extend({
  components: {
    MnemonicContainer,
    AppButton,
  },
  data: (): { mnemonics: string[] } => ({
    mnemonics: TEMP_MNEMONICS,
  }),
  methods: {
    copy(): void {
      const mnemonicsInString: string = this.mnemonics
        .toString()
        .replace(/,/g, ' ');
      navigator.clipboard.writeText(mnemonicsInString);
    },
  },
});
