import Vue, { PropType } from 'vue';
import { currency } from '@/filters/currency';
import { PreparedInvoice } from '@/client/requests-types';
import { InvoiceItemData } from '@/views/dashboard/invoice-item/invoice-item-types';

export default Vue.extend({
  filters: {
    currency,
  },

  props: {
    invoice: {
      type: Object as PropType<PreparedInvoice>,
      required: true,
    },

    type: {
      type: String,
      required: false,
      default: 'default',
    },
  },
  data(): InvoiceItemData {
    return {
      check: false,
    };
  },
});
