import Vue from 'vue';
import gsap from 'gsap';

export default Vue.extend({
  mounted(): void {
    const tl = gsap.timeline({ repeat: -1, repeatDelay: 3 });

    tl.to('.main_manage__first_image_coins', {
      duration: 1,
      opacity: 0,
      scale: 0,
      y: -200,
      ease: 'power1',
    });

    tl.from('.main_manage__second_image_coins', {
      opacity: 0,
      duration: 0.1,
      ease: 'power1',
    });

    tl.to('.main_manage__second_image_coins', {
      duration: 0.7,
      opacity: 0,
      scale: 1,
      y: 100,
      ease: 'power1',
    });

    tl.from('.main_manage__third_image_coins', {
      opacity: 0,
      duration: 0.1,
      ease: 'power1',
    });

    tl.to('.main_manage__third_image_coins', {
      duration: 0.7,
      opacity: 0,
      scale: 1,
      y: 100,
      ease: 'power1',
    });
  },
});
