import Vue from 'vue';
import { mapFields } from 'vuex-map-fields';
import { BLOCKCHAINS as blockchains } from '@/common';
import { PaymentDetailsData } from '@/modals/add-invoice/payment-details/payment-details-types';

export default Vue.extend({
  data(): PaymentDetailsData {
    return {
      blockchains,
    };
  },
  computed: {
    ...mapFields('addInvoiceModal', {
      address: 'paymentDetails.address',
      blockchain: 'paymentDetails.blockchain',
    }),
  },
});
