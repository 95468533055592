import { render, staticRenderFns } from "./reset-password.html?vue&type=template&id=b392a3a2&"
import script from "./reset-password.ts?vue&type=script&lang=js&"
export * from "./reset-password.ts?vue&type=script&lang=js&"
import style0 from "./reset-password.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./reset-password.i18n.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fpages%2Fauth%2Freset-password%2Freset-password.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports