import { BACKEND } from '@/remotes';

export const MAIL_SEND = 'mailSend';

export default () => ({
  namespaced: true,
  actions: {
    postEnquiry(_, { name, phone, email, message, recaptchaToken }) {
      return BACKEND.post(
        'collaboration/enquiries',
        { name, phone, email, message },
        {
          headers: {
            'X-RECAPTCHA-TOKEN': recaptchaToken,
          },
        }
      );
    },
  },
});
