import Vue from 'vue';
import { MainStartData } from './main-start-types';

export default Vue.extend({
  data(): MainStartData {
    return {
      lineWidth: 0,
      STEPS: [
        {
          title: this.$t('card.first.title'),
          image: require('@/assets/img/step_image_1.svg'),
        },

        {
          title: this.$t('card.second.title'),
          image: require('@/assets/img/step_image_2.svg'),
        },

        {
          title: this.$t('card.third.title'),
          image: require('@/assets/img/step_image_3.svg'),
        },

        {
          title: this.$t('card.forth.title'),
          image: require('@/assets/img/step_image_4.svg'),
        },
      ],
    };
  },
  methods: {
    viewHandler(e: { percentTop: number; percentCenter: number }): void {
      const size = 1 - e.percentTop;
      this.lineWidth = Math.round(size * 160)
    },
  },
});
