// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/default_avatar.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/img/icons-sprite-blue.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".avatar_upload__block{text-align:center;display:grid;justify-items:center}.avatar_upload__block .avatar_upload__wrapper{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:100px;height:100px;background-color:#f9f9f9;background-position:50%;border-radius:50%;position:relative;margin-bottom:15px;margin-top:5px}.avatar_upload__block .avatar_upload__wrapper .app__button__icon{position:absolute;bottom:-10px;right:-10px;background-color:#fff;color:#1436e5;-webkit-box-shadow:0 3px 5px rgba(20,54,229,.1);box-shadow:0 3px 5px rgba(20,54,229,.1);border-radius:50%}.avatar_upload__block .avatar_upload__wrapper .app__button__icon.v-btn:not(.v-btn--round).v-size--default{min-width:42px;min-height:42px;padding:0}.avatar_upload__block .avatar_upload__wrapper .app__button__icon__icon{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");min-width:24px;width:24px;max-width:24px;min-height:24px;height:24px;max-height:24px;background-repeat:no-repeat;background-position:-312px -24px}.avatar_upload__block .avatar_upload__email_wrapper{margin-top:5px;font-weight:400;font-size:14px;line-height:140%;color:#b9babf}.avatar_upload__block .avatar_upload__version_wrapper{background:#f6fbfb;border-radius:3px;padding:5px 10px;margin-top:10px;color:#8a8b93;font-weight:400;font-size:14px;line-height:140%}", ""]);
// Exports
module.exports = exports;
