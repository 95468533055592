import Vue from 'vue';
import { ProfileSettings as ProfileSettingsModal } from '@/modals';
import { mapActions } from 'vuex';
import { UserInfoData } from '@/views/dashboard/user-info/user-info-types';

export default Vue.extend({
  data(): UserInfoData {
    return {
      openMenu: false,
    };
  },
  methods: {
    ...mapActions('modals', { modalOpen: 'open' }),

    openSettingsModal(): void {
      this.modalOpen({
        factory: () => ProfileSettingsModal,
        options: {
          fullscreen: true,
        },
        data: {
          title: this.$t('modal.settings'),
        },
      });
    },
  },
});
