import Vue from 'vue';
import { AppButton } from '@/components';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { currency } from '@/filters/currency';

export default Vue.extend({
  filters: {
    currency,
  },
  components: {
    AppButton,
  },
  computed: {
    ...mapGetters('addInvoiceModal', { total: 'itemsTotalAmount' }),
    ...mapState('addInvoiceModal', ['currency', 'showFrom', 'showTitle']),
  },
  methods: {
    ...mapMutations('addInvoiceModal', ['toggleShow']),
  },
});
