import Vue from 'vue';
import TableItem from '@/modals/add-invoice/invoice-items/table-item/table-item.vue';
import { CURRENCIES as currencies } from '@/common';
import { mapFields } from 'vuex-map-fields';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { AppButton } from '@/components';
import { currency } from '@/filters/currency';
import { InvoicesItemsData } from '@/modals/add-invoice/invoice-items/invoices-items-types';

export default Vue.extend({
  filters: {
    currency,
  },
  components: {
    TableItem,
    AppButton,
  },
  data(): InvoicesItemsData {
    return {
      currencies,
    };
  },
  computed: {
    ...mapState('addInvoiceModal', {
      invoiceItems: 'items',
      currency: 'currency',
      countTypes: 'types',
    }),
    ...mapFields('addInvoiceModal', ['countType', 'currency']),
    ...mapGetters('addInvoiceModal', ['itemsTotalAmount']),
  },
  methods: {
    ...mapMutations('addInvoiceModal', {
      addInvoiceItem: 'addItem',
    }),
  },
});
