import Vue, { VNode, PropType } from 'vue';
import { AppButton } from '@/components';
import { MultiStepData } from './multi-step-types';

export default Vue.extend({
  components: {
    AppButton,
  },
  props: {
    components: {
      type: Array as PropType<VNode[]>,
      required: true,
    },
    sectionType: {
      type: String,
      required: true,
      validator: (v: string): boolean =>
        ['create', 'add', 'recover'].includes(v),
    },
  },
  data(): MultiStepData {
    return {
      currentComponentIndex: 0,
      isValid: false,
    };
  },
  computed: {
    component(): VNode {
      return this.components[this.currentComponentIndex];
    },

    isFirstStep(): boolean {
      return this.currentComponentIndex === 0;
    },
    isLastStep(): boolean {
      return this.components.length - 1 === this.currentComponentIndex;
    },
  },
  methods: {
    onNext(): void {
      if (this.isLastStep) {
        // Do stuff
        return;
      }
      const form = this.$refs.form as HTMLFormElement;
      form.validate();

      this.currentComponentIndex += 1;
    },
    onPrevious(): void {
      if (this.isFirstStep) {
        //  Do stuff
        return;
      }

      this.currentComponentIndex -= 1;
    },
  },
});
