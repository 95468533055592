import Vue from 'vue';
import { LocaleMessage } from 'vue-i18n';
import { ValidatorsData } from '@/mixins/validators-types';

export default Vue.extend({
  data(): ValidatorsData {
    return {
      required: [
        (v: string): LocaleMessage | boolean =>
          v ? true : this.$t('global.field.error.requiredError'),
      ],
      emailRule: [
        (v: string): LocaleMessage | boolean =>
          /.+@.+\..+/.test(v) ? true : this.$t('global.field.error.emailError'),
      ],
      minRule: [
        (v: string): LocaleMessage | boolean =>
          v.length > 7 ? true : this.$t('global.field.error.minError'),
      ],
    };
  },
});
