var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invoice_items__container"},[_c('div',{staticClass:"modal__title"},[_vm._v(_vm._s(_vm.$t('invoiceItems')))]),_c('div',{staticClass:"invoice_items__hours_block"},[_c('v-select',{staticClass:"app__select_field",attrs:{"dense":"","item-value":function (item) { return item; },"item-text":function (ref) {
	var name = ref.name;

	return name;
},"outlined":"","items":_vm.countTypes},model:{value:(_vm.countType),callback:function ($$v) {_vm.countType=$$v},expression:"countType"}}),_c('v-select',{staticClass:"app__select_field",attrs:{"dense":"","item-value":function (item) { return item; },"item-text":function (ref) {
	var symbol = ref.symbol;

	return symbol;
},"outlined":"","items":_vm.currencies},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}})],1),_c('div',{staticClass:"item_list__container"},[_c('div',{staticClass:"item_list__table table_header",class:_vm.countType.type},[_c('span',[_vm._v(_vm._s(_vm.$t('description')))]),(_vm.countType.type === 'amount')?[_c('span',[_vm._v(" Amount, "+_vm._s(_vm.currency.symbol)+" ")])]:[_c('span',[_vm._v("Rate, "+_vm._s(_vm.currency.symbol))]),_c('span',[_vm._v(_vm._s(_vm.countType.type === 'hours' ? 'Hours' : 'Qty'))]),_c('span',[_vm._v("Subtotal, "+_vm._s(_vm.currency.symbol))])]],2),_c('div',{staticClass:"item_list__table table_body"},_vm._l((_vm.invoiceItems),function(ref,index){
var id = ref.id;
return _c('table-item',{key:id,attrs:{"count-type":_vm.countType,"index":index}})}),1)]),_c('v-row',{staticClass:"invoice_items__action_wrapper",attrs:{"dense":""}},[_c('v-col',[_c('app-button',{staticClass:"app__button text",on:{"click":_vm.addInvoiceItem}},[_c('div',{staticClass:"app__button_icon icon-blue app__icon plus"}),_vm._v(" "+_vm._s(_vm.$t('addButton'))+" ")])],1),_c('v-col',{staticClass:"text-right invoice_items__total_block"},[_c('span',{staticClass:"invoice_items__total_text"},[_vm._v(_vm._s(_vm.$t('global.total')))]),_c('span',{staticClass:"invoice_items__total_amount"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.itemsTotalAmount))+" "+_vm._s(_vm.currency.symbol)+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }