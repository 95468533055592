import Vue, { PropType } from 'vue';
import { ProductCard } from '../main-products-types';
import { AppButton } from '@/components';

export default Vue.extend({
  components: {
    AppButton,
  },
  props: {
    product: {
      type: Object as PropType<ProductCard>,
      required: true,
    },
  },
});
