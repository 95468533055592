import Vue from 'vue';
import { AppButton } from '@/components';

export default Vue.extend({
  components: {
    AppButton,
  },
  data() {
    return {
      showCookies: true,
    };
  },
  created(): void {
    if (localStorage.getItem('acceptCookies') === 'true') {
      this.showCookies = false;
    }
  },
  methods: {
    acceptCookies(): void {
      localStorage.setItem('acceptCookies', 'true');

      this.showCookies = !this.showCookies;
    },
  },
});
