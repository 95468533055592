import Vue from 'vue';

export const currency = Vue.filter('currency', (value: string): string => {
  let output;

  const intPart = Math.floor(Number(value));
  const intPartFormat = intPart
    .toString()
    .replace(/(\d)(?=(?:\d{3})+$)/g, '$1 ');

  let floatPart = '.00';
  const value2Array = value.toString().split('.');
  if (value2Array.length === 2) {
    floatPart = value2Array[1].toString();
    if (floatPart.length === 1) {
      output = intPartFormat + '.' + floatPart + '0';
    } else {
      output = intPartFormat + '.' + floatPart;
    }
  } else {
    output = intPartFormat + floatPart;
  }
  return output;
});
