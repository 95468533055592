// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/bg_1_top.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".main__container{width:100%;margin-left:auto;margin-right:auto}.section{padding-right:30px;padding-left:30px}.main_card__container{display:grid;grid-column-gap:5px;grid-row-gap:5px;grid-template-columns:1fr 1fr 1fr}.main__button.x-large.v-btn:not(.v-btn--round).v-size--default{max-width:245px;height:68px;padding:22px 40px}.main__button.x-large.v-btn:not(.v-btn--round).v-size--default .v-btn__content{text-transform:uppercase;font-weight:900;font-size:14px;line-height:120%;letter-spacing:.15em}.main__button.x-large.turqoise-border{border:2px solid #50eff5}.main__button.x-large.blue-border{border:2px solid #1436e5}.main__button.x-large.text-white{color:#fff}.main__button.x-large.text-blue{color:#1436e5}@media only screen and (min-width: 1905px){.main__container{width:1170px}}@media only screen and (min-width: 1265px)and (max-width: 1904px){.main__container{width:1170px}}@media only screen and (min-width: 961px)and (max-width: 1264px){.main__container{width:auto}.main_card__container{grid-template-columns:1fr 1fr 1fr;overflow:hidden !important}}@media only screen and (min-width: 600px)and (max-width: 959px){.main_card__container{grid-template-columns:1fr;overflow:hidden !important}}@media only screen and (max-width: 599px){.main_card__container{grid-template-columns:1fr;overflow:hidden !important}.section{padding-right:20px;padding-left:20px}}.bg_img_1{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");overflow:hidden;background-position:100% 0;background-size:60vw;background-repeat:no-repeat;position:absolute;top:0;bottom:0;right:0;height:100%;width:100%;z-index:0}", ""]);
// Exports
module.exports = exports;
