import { render, staticRenderFns } from "./layout.html?vue&type=template&id=64a7a6ed&"
import script from "./layout.ts?vue&type=script&lang=js&"
export * from "./layout.ts?vue&type=script&lang=js&"
import style0 from "./layout.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports