import Vue from 'vue';
import ProductCard from './product-card/product-card.vue';
import { MainProductsData } from './main-products-types';
import { cardAnimations } from '@/views/main/common';
import CardAnimation from '@/mixins/card-animation.ts';

export default Vue.extend({
  components: {
    ProductCard,
  },
  mixins: [CardAnimation],
  data(): MainProductsData {
    return {
      PRODUCTS: [
        {
          logo: require('@/assets/img/laborx_logo_new.svg'),
          title: this.$t('laborx.title'),
          description: this.$t('laborx.description'),
          link: 'https://laborx.com/',
        },
        {
          logo: require('@/assets/img/timex_logo_new.svg'),
          title: this.$t('timex.title'),
          description: this.$t('timex.description'),
          link: 'https://timex.io/',
        },
        {
          logo: require('@/assets/img/audt_logo_new.svg'),
          title: this.$t('audt.title'),
          description: this.$t('audt.description'),
          link: 'https://audt.to/',
        },
      ],
      cardAnimations,
    };
  },
});
