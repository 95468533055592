import Vue, { PropType } from 'vue';
import { CreateElement, VNode } from 'vue/types/umd';
import { Component } from 'vue/types';

export default Vue.extend({
  props: {
    component: {
      type: Function as PropType<Component>,
      required: true,
    },
  },
  render(createElement: CreateElement): VNode {
    return createElement(this.component);
  },
});
