export type Nullable<T> = T | null;

export interface BlockchainInfo {
  symbol: string;
  blockchain: string;
  network: string;
}

export interface CurrencyInfo {
  name: string;
  symbol: string;
}

export const NETWORK = {
  MAINNET: 'mainnet',
  TESTNET: 'testnet',
};

export const BLOCKCHAIN = {
  BTC: 'BTC',
  ETH: 'ETH',
};

export const BLOCKCHAIN_NAME = {
  [BLOCKCHAIN.BTC]: 'Bitcoin',
  [BLOCKCHAIN.ETH]: 'Ethereum',
};

export const BLOCKCHAINS: BlockchainInfo[] = [
  {
    symbol: BLOCKCHAIN.BTC,
    blockchain: BLOCKCHAIN_NAME[BLOCKCHAIN.BTC],
    network: NETWORK.MAINNET,
  },
  {
    symbol: BLOCKCHAIN.BTC,
    blockchain: BLOCKCHAIN_NAME[BLOCKCHAIN.BTC],
    network: NETWORK.TESTNET,
  },
  {
    symbol: BLOCKCHAIN.ETH,
    blockchain: BLOCKCHAIN_NAME[BLOCKCHAIN.ETH],
    network: NETWORK.MAINNET,
  },
  {
    symbol: BLOCKCHAIN.ETH,
    blockchain: BLOCKCHAIN_NAME[BLOCKCHAIN.ETH],
    network: NETWORK.TESTNET,
  },
];

export const CURRENCIES: CurrencyInfo[] = [
  {
    name: 'American Dollars',
    symbol: 'USD',
  },
  {
    name: 'Russian Rubles',
    symbol: 'RUS',
  },
  {
    name: 'European Euros',
    symbol: 'EUR',
  },
  {
    name: 'Australlian Dollars',
    symbol: 'AUD',
  },
];
