import Vue, { PropType } from 'vue';
import { Plan } from '@/pages/public/pricing/pricing-types';
import { AppButton } from '@/components';

export default Vue.extend({
  components: {
    AppButton,
  },
  props: {
    plan: {
      type: Object as PropType<Plan>,
      required: true,
    },
  },
});
