import Vue from 'vue';
import { MainBreadcrumb } from '@/views/main';

export default Vue.extend({
  metaInfo: {
    title: 'Terms of use',
  },
  components: {
    MainBreadcrumb,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: this.$t('button.home'),
          disabled: false,
          to: '/',
        },
        {
          text: this.$t('button.terms'),
          disabled: true,
          to: '/tc',
        },
      ],
    };
  },
});
