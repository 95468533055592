import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { State as RootState } from '@/store/state';
import { getField, updateField } from 'vuex-map-fields';
import { Request } from '@/client/requests-types';
import { getRequests } from '@/client/requests';
import {
  RequestState,
  RequestsContext,
} from '@/store/client/modules/requests/requests-types';

export const STATE: RequestState = {
  requests: [],
};

export const actions: ActionTree<RequestState, RootState> = {
  fetchRequests({ commit }: RequestsContext): void {
    // getRequests will be changed to async function that gets requests from server
    const requests: Request[] = getRequests();

    commit('setRequests', requests);
  },
};

export const mutations: MutationTree<RequestState> = {
  updateField,

  setRequests(state: RequestState, requests: Request[]): void {
    state.requests = requests;
  },
};

export const getters: GetterTree<RequestState, RootState> = {
  getField,
};

export const requestModule: Module<RequestState, RootState> = {
  namespaced: true,

  state: { ...STATE },
  getters,
  mutations,
  actions,
};

export default requestModule;
