import { render, staticRenderFns } from "./billing-info.html?vue&type=template&id=1fef072a&"
import script from "./billing-info.ts?vue&type=script&lang=js&"
export * from "./billing-info.ts?vue&type=script&lang=js&"
import style0 from "./billing-info.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./billing-info.i18n.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodals%2Fprofile-settings%2Fbilling-info%2Fbilling-info.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports