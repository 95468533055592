import Vue, { PropType } from 'vue';
import { currency } from '@/filters/currency';
import { mapMutations } from 'vuex';
import { InvoiceItem } from '@/store/client/modules/add-invoice-modal/add-invoice-modal-types';
import { Type } from '@/store/client/modules/add-invoice-modal/add-invoice-modal-types';
import { TableItemData } from '@/modals/add-invoice/invoice-items/table-item/table-item-types';

export default Vue.extend({
  filters: {
    currency,
  },
  props: {
    countType: {
      type: Object as PropType<Type>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
      validator: (v: number): boolean => v >= 0,
    },
  },
  data(): TableItemData {
    return {
      form: {
        description: '',
        rate: 1,
        amount: '',
      },
    };
  },
  computed: {
    total(): number {
      if (!this.form.amount || !this.form.rate) {
        return 0;
      }

      return Number(this.form.amount) * Number(this.form.rate);
    },
  },
  watch: {
    form: {
      handler(newValue: InvoiceItem): void {
        this.changeItem({
          index: this.index,
          value: { ...newValue, total: this.total },
        });
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations('addInvoiceModal', ['changeItem', 'deleteItem']),
  },
});
