import Vue from 'vue';

export default Vue.extend({
  metaInfo: {
    title: 'Not Found',
  },
  mounted(): void {
    console.log(this.$route);
  },
});
