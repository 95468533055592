import { ActionContext } from 'vuex';
import { State as RootState } from '@/store/state';

export const AUTH_VIEW = {
  reset: 'reset',
  signin: 'signin',
  signup: 'signup',
  check: 'check',
};

export interface AuthPageState {
  currentView: string;
  meta?: {
    email?: string;
  };
}

export type AuthPageContext = ActionContext<AuthPageState, RootState>;
