// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/icons-sprite-grey.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/img/icons-sprite-light-grey.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".attachments__container{width:492px}.attachments__container .attachments__attachment_wrapper .attachments__attachment_item{display:grid;grid-template-columns:24px 1fr 24px;margin:20px 0}.attachments__container .attachments__attachment_wrapper .attachments__attachment_item .icon{min-width:24px;width:24px;max-width:24px;min-height:24px;height:24px;max-height:24px;cursor:pointer;background-repeat:no-repeat}.attachments__container .attachments__attachment_wrapper .attachments__attachment_item .attachments__attachment_icon{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:-144px -48px}.attachments__container .attachments__attachment_wrapper .attachments__attachment_item .attachments__attachment_name{padding:0 11px;line-height:25px;color:#5b5d69;font-weight:400;font-size:14px}.attachments__container .attachments__attachment_wrapper .attachments__attachment_item .attachments__attachment_action{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-position:-216px -24px}", ""]);
// Exports
module.exports = exports;
