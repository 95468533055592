import Vue from 'vue';
import { ComponentAsProps } from './layout-types';

export default Vue.extend({
  props: {
    components: {
      type: Array,
      required: true,
    },
    sidebar: {
      type: Function as ComponentAsProps,
      required: false,
      default: null,
    },
  },
});
