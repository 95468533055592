import Vue from 'vue';

export default Vue.extend({
  props: {
    number: {
      type: Number,
      required: false,
      default: (): number => 0,
    },
    word: {
      type: String,
      required: false,
      default: (): string => '',
    },
  },
});
