import { PreparedInvoice, Request } from './requests-types';
import { mockedData } from '@/client/requests-mock-data';

export const prepareInvoice = (request: Request): PreparedInvoice => {
  const income = request.userEmail === request.invoice.payerAddress;

  const blockchain =
    request.paymentNetwork === 'mainnet'
      ? `${request.paymentAsset}`
      : `${request.paymentAsset} ${request.paymentNetwork.replace(/^\w/, c =>
          c.toUpperCase()
        )}`;

  return {
    income,
    blockchain,
    amount: request.settlementAmount,
    createdAt: request.createdAt,
    currency: request.settlementAsset,
    email: request.userEmail,
    id: request.requestId,
    number: request.invoice.number,
    status: request.status,
    type: request.status,
    updatedAt: request.updatedAt,
  };
};

export const getRequests = (): Request[] => {
  return mockedData;
};
