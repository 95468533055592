import Vue from 'vue';
import InvoiceSidebar from '@/modals/add-invoice/invoice-sidebar/invoice-sidebar.vue';
import ModalLayout from '@/modals/layout/layout.vue';
import InvoiceDetails from '@/modals/add-invoice/invoice-details/invoice-details.vue';
import PaymentDetails from '@/modals/add-invoice/payment-details/payment-details.vue';
import InvoiceItems from '@/modals/add-invoice/invoice-items/invoice-items.vue';
import Attachments from '@/modals/add-invoice/attachments/attachments.vue';
import NoteContainer from '@/modals/add-invoice/note-container/note-container.vue';
import { AddInvoiceData } from '@/modals/add-invoice/add-invoice-types';

export default Vue.extend({
  components: {
    ModalLayout,
  },
  data(): AddInvoiceData {
    return {
      components: [
        InvoiceDetails,
        PaymentDetails,
        InvoiceItems,
        Attachments,
        NoteContainer,
      ],
      sidebar: InvoiceSidebar,
    };
  },
});
