import Vue from 'vue';
import { Process } from '../main-find-more-types';

export default Vue.extend({
  props: {
    process: {
      type: Object as () => Process,
      required: true,
    },
  },
});
