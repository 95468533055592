import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';
import { State as RootState } from '@/store/state';
import {
  AuthPageState,
  AuthPageContext,
} from '@/store/client/modules/auth-page/auth-page-types';

export const STATE: AuthPageState = {
  currentView: '',
  meta: {
    email: '',
  },
};

export const getters: GetterTree<AuthPageState, RootState> = {
  getField,
};

export const actions: ActionTree<AuthPageState, RootState> = {
  changeView(
    { commit }: AuthPageContext,
    { currentView, meta }: AuthPageState
  ): void {
    commit('CHANGE_PAGE', { currentView, meta });
  },
};

export const mutations: MutationTree<AuthPageState> = {
  updateField,

  CHANGE_PAGE(state: AuthPageState, { currentView, meta }: AuthPageState) {
    state.currentView = currentView;

    if (meta) {
      state.meta = meta;
    }
  },
};

export const AuthPageModule: Module<AuthPageState, RootState> = {
  namespaced: true,

  state: { ...STATE },
  getters,
  actions,
  mutations,
};

export default AuthPageModule;
