import Vue, { VueConstructor } from 'vue';
import Check from './check/check.vue';
import Signin from './signin/signin.vue';
import Signup from './signup/signup.vue';
import ResetPassword from './reset-password/reset-password.vue';
import { mapState } from 'vuex';

const AUTH_VIEW: { [key: string]: VueConstructor } = {
  reset: ResetPassword,
  signup: Signup,
  signin: Signin,
  check: Check,
};

export default Vue.extend({
  components: {
    Check,
    Signin,
    Signup,
    ResetPassword,
  },
  data() {
    return {
      authView: AUTH_VIEW,
    };
  },
  computed: {
    ...mapState('authPage', ['currentView', 'meta']),
  },
});
