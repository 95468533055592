import Vue from 'vue';
import { AppButton } from '@/components';
import { ChangePasswordData } from './change-password-types';

export default Vue.extend({
  components: {
    AppButton,
  },
  data(): ChangePasswordData {
    return {
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
    };
  },
});
