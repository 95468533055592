export const TEMP_MNEMONICS: string[] = [
  'return',
  'hard',
  'cattle',
  'undo',
  'anxiety',
  'acoustic',
  'word',
  'screen',
  'reflect',
  'indoor',
  'rapid',
  'price',
];
