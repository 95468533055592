import axios from 'axios';

/* eslint-disable-next-line no-undef */
const config = __APP_CONFIG__;

export const withAuthorization = (authorization, config = {}) => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: `Bearer ${authorization}`,
  },
});

export const BACKEND = axios.create(config.backendService.endpoint);
