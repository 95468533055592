import * as modulesClient from '@/store/client';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import * as modulesServer from '@/store/server';
import { Store } from 'vuex';

export default {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  async beforeReady({ store }: { store: Store }): Promise<void> {
    if (process.client) {
      for (const [key, module] of Object.entries(modulesClient)) {
        await store.registerModule(key, module);
      }
    } else {
      for (const [key, module] of Object.entries(modulesServer)) {
        await store.registerModule(key, module);
      }
    }
  },
};
