import Vue from 'vue';
import ModalLayout from '@/modals/layout/layout.vue';
import Sidebar from './sidebar/sidebar.vue';
import AvatarUpload from './avatar-upload/avatar-upload.vue';
import PersonalInformation from './personal-information/personal-information.vue';
import PrimaryAsset from './primary-asset/primary-asset.vue';
import PaymentAssets from './payment-assets/payment-assets.vue';
import ReceiveInvoice from './receive-invoice/receive-invoice.vue';
import BillingInfo from './billing-info/billing-info.vue';
import ChangePassword from './change-password/change-password.vue';
import { mapMutations, mapState } from 'vuex';
import { ProfileSettingsData } from './profile-settings-types';

export const components = {
  profile: [
    AvatarUpload,
    PersonalInformation,
    PrimaryAsset,
    PaymentAssets,
    ReceiveInvoice,
    BillingInfo,
  ],
  password: [ChangePassword],
};

export default Vue.extend({
  components: {
    ModalLayout,
  },
  data(): ProfileSettingsData {
    return {
      components,
      sidebar: Sidebar,
    };
  },
  computed: {
    ...mapState('profileSettingsModal', {
      activeComponent: 'component',
    }),
  },
  beforeDestroy(): void {
    this.resetFields();
  },
  methods: {
    ...mapMutations('profileSettingsModal', ['resetFields']),
  },
});
