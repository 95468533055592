import Vue, { PropType } from 'vue';
import Proxy from '@/components/proxy/proxy.vue';
import { mapActions } from 'vuex';
import { AppModalData } from './app-modal-types';
import { Modal } from '@/store/client/modules/modals/modals-types';

export default Vue.extend({
  components: {
    Proxy,
  },
  props: {
    modal: {
      type: Object as PropType<Modal>,
      required: true,
    },
  },
  data(): AppModalData {
    return {
      open: true,
    };
  },
  watch: {
    open: {
      handler(value: boolean): void {
        if (!value) {
          this.close();
        }
      },
    },
  },
  methods: {
    ...mapActions('modals', {
      closeModal: 'close',
    }),

    close(): void {
      const form = this.$el.querySelector('form');

      if (form) {
        form.reset();
      }

      this.closeModal();
    },
  },
});
